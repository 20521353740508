import React, { useState } from "react"
import { mobileMenuData } from "../data"

const AboutUs = () => {
  // const [active, setActive] = useState(1)
  return (
    <section className="video-box-design-guide sec-pad service-tab-box">
      <div className="thm-container">
        <div className="single-tab-content tab-pane show fade in active animated fadeIn">
          <div className="sec-title text-center mb0">
            <span>About Us</span>
            <h3>Our Introduction</h3>
            <p>{mobileMenuData.description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
